<template>
  <!-- logo -->
  <div class="login hors-dashboard">
    <div class="img-large">
      <picture>
        <source srcset="https://cdn.detectivebox.fr/woodlock/img/logo-monogramme.webp" type="image/webp" />
        <img class="logo-icon" src="https://cdn.detectivebox.fr/woodlock/img/logo-monogramme.png" alt="" />
      </picture>
      <picture>
        <source srcset="https://cdn.detectivebox.fr/woodlock/img/logo-texte.webp" type="image/webp" />
        <img class="logo-text" src="https://cdn.detectivebox.fr/woodlock/img/logo-texte.png" alt="" />
      </picture>
    </div>
    <!-- formulaire de reinitialisation de mot de passe -->
    <form @submit.prevent="formHandler">
      <h1>Nouveau mot de passe</h1>
      <div class="input-container">
        <div class="fa"><vue-feather tag="div" type="mail" /></div>
        <label for="password">Nouveau mot de passe</label>
        <input type="password" name="password" v-model="password" placeholder="Mot de passe" />
      </div>
      <input class="button" type="submit" value="ENVOYER" />
    </form>
    <router-link class="button button-secondary button-link" :to="{ name: 'Login' }">
      <span>RETOUR</span>
    </router-link>
    <div class="overlay"></div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: {
    authId: String,
    token: String,
  },
  data() {
    return {
      password: "",
    };
  },
  methods: {
    // récupère l'action reset du store
    ...mapActions(["reset"]),
    // fonction qui est éxécuté en cas d'envoi du formulaire
    async formHandler() {
      // éxécute reset avec les valeur du formulaire et les valeurs passés dans l'URL
      await this.reset({
        token: this.token,
        authId: this.authId,
        password: this.password,
      });
      // recharge l'app pour s'assurer du chargement des données nécéssaire pour le fonctionement du reste du site.
      window.location.href = "/";
    },
  },
};
</script>
